<template>
  <div>
  <div class="content">
    
  <div class="h-left">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/home' }" id="home1">首页</el-breadcrumb-item>
          <el-breadcrumb-item v-for="item in navList" :key="item.title" :to="{ path: item.path }">
            {{ item.title
            }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    
    <div class="hright">
      <!-- <span>欢迎，{{ text }}</span> -->

      <el-dropdown @command="handleCommand">
        <!-- 下拉标题 -->
        <span class="el-dropdown-link">
          欢迎，{{ text }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <!-- 下拉项 -->
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-image
        src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
        fit="cover"
      ></el-image>
    </div>
    
  </div>
  <div v-show="hide">
  <HomeView></HomeView>
</div>
</div>
</template>

<script>
import HomeView from '../views/HomeView.vue';
// import bus from '../common/bus';
export default {
  name: "MyHeader",
  components:{
    HomeView
  },
  data() {
    return {
      navList: [],
      text: "",
      hide:true,
    };
  },
  created(){
    let url=location.href.indexOf("/home")
    // console.log(url)
    if (url!==-1) {
        this.hide=true
      }else{
        this.hide=false
      }
  },

  mounted() {
    
    this.bus.$on('block',data=>{

      if (data=="/home") {
        this.hide=true
      }else{
        this.hide=false
      }
      
    })
  },
  methods: {
    handleCommand(val) {
    
      if (val == 1) {
        this.bus.$emit("quit",true)
        //退出登录
        // 清除token
        localStorage.removeItem("token");
        localStorage.removeItem("lastLogin");
        // 跳转到登录页面
        this.$router.replace("/login");
      }
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        const arr = [];
        this.$route.matched.forEach(item => {
          if (!item.meta.title) return;
          arr.push({
            title: item.meta.title,
            path: item.path
          });
        });
        this.navList = arr;
        // 判断路由路径
        if (this.$route.path== "/home") {
          this.hide=true
        } else {
          this.hide=false
        }
      }
    }
  }
};
</script>


<style lang="less" scoped>
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  .h-left{
    display: flex;
    align-items: center;
  }
  .el-breadcrumb {
    height: 100%;
  }

  #home1 {
    /deep/ .is-link {
      font-weight: 600;
      color: black;
    }
  }

  .hright {
    display: flex;
    align-items: center;

    .el-image {
      width: 40px;
      height: 40px;
      margin-left: 10px;
      border-radius: 50%;
    }
  }
}
</style>