export default [{
    label: "表情",
    children: [{
        className: "bg-1f604",
        icon: "😄"
      },
      {
        className: "bg-1f603",
        icon: "😃"
      },
      {
        className: "bg-1f600",
        icon: "😀"
      },
      {
        className: "bg-1f60a",
        icon: "😊"
      },
      {
        className: "bg-1f609",
        icon: "😉"
      },
      {
        className: "bg-1f60d",
        icon: "😍"
      },
      {
        className: "bg-1f618",
        icon: "😘"
      },
      {
        className: "bg-1f61a",
        icon: "😚"
      },
      {
        className: "bg-1f617",
        icon: "😗"
      },
      {
        className: "bg-1f619",
        icon: "😙"
      },
      {
        className: "bg-1f61c",
        icon: "😜"
      },
      {
        className: "bg-1f61d",
        icon: "😝"
      },
      {
        className: "bg-1f61b",
        icon: "😛"
      },
      {
        className: "bg-1f633",
        icon: "😳"
      },
      {
        className: "bg-1f601",
        icon: "😁"
      },
      {
        className: "bg-1f614",
        icon: "😔"
      },
      {
        className: "bg-1f60c",
        icon: "😌"
      },
      {
        className: "bg-1f612",
        icon: "😒"
      },
      {
        className: "bg-1f61e",
        icon: "😞"
      },
      {
        className: "bg-1f623",
        icon: "😣"
      },
      {
        className: "bg-1f622",
        icon: "😢"
      },
      {
        className: "bg-1f602",
        icon: "😂"
      },
      {
        className: "bg-1f62d",
        icon: "😭"
      },
      {
        className: "bg-1f62a",
        icon: "😪"
      },
      {
        className: "bg-1f625",
        icon: "😥"
      },
      {
        className: "bg-1f630",
        icon: "😰"
      },
      {
        className: "bg-1f605",
        icon: "😅"
      },
      {
        className: "bg-1f613",
        icon: "😓"
      },
      {
        className: "bg-1f629",
        icon: "😩"
      },
      {
        className: "bg-1f62b",
        icon: "😫"
      },
      {
        className: "bg-1f628",
        icon: "😨"
      },
      {
        className: "bg-1f631",
        icon: "😱"
      },
      {
        className: "bg-1f620",
        icon: "😠"
      },
      {
        className: "bg-1f621",
        icon: "😡"
      },
      {
        className: "bg-1f624",
        icon: "😤"
      },
      {
        className: "bg-1f616",
        icon: "😖"
      },
      {
        className: "bg-1f606",
        icon: "😆"
      },
      {
        className: "bg-1f60b",
        icon: "😋"
      },
      {
        className: "bg-1f637",
        icon: "😷"
      },
      {
        className: "bg-1f60e",
        icon: "😎"
      },
      {
        className: "bg-1f634",
        icon: "😴"
      },
      {
        className: "bg-1f635",
        icon: "😵"
      },
      {
        className: "bg-1f632",
        icon: "😲"
      },
      {
        className: "bg-1f61f",
        icon: "😟"
      },
      {
        className: "bg-1f626",
        icon: "😦"
      },
      {
        className: "bg-1f627",
        icon: "😧"
      },
      {
        className: "bg-1f608",
        icon: "😈"
      },
      {
        className: "bg-1f47f",
        icon: "👿"
      },
      {
        className: "bg-1f62e",
        icon: "😮"
      },
      {
        className: "bg-1f62c",
        icon: "😬"
      },
      {
        className: "bg-1f610",
        icon: "😐"
      },
      {
        className: "bg-1f615",
        icon: "😕"
      },
      {
        className: "bg-1f62f",
        icon: "😯"
      },
      {
        className: "bg-1f636",
        icon: "😶"
      },
      {
        className: "bg-1f607",
        icon: "😇"
      },
      {
        className: "bg-1f60f",
        icon: "😏"
      },
      {
        className: "bg-1f611",
        icon: "😑"
      },
      {
        className: "bg-1f63a",
        icon: "😺"
      },
      {
        className: "bg-1f638",
        icon: "😸"
      },
      {
        className: "bg-1f63b",
        icon: "😻"
      },
      {
        className: "bg-1f63d",
        icon: "😽"
      },
      {
        className: "bg-1f63c",
        icon: "😼"
      },

      {
        className: "bg-1f640",
        icon: "🙀"
      },
      {
        className: "bg-1f63f",
        icon: "😿"
      },
      {
        className: "bg-1f639",
        icon: "😹"
      },
      {
        className: "bg-1f63e",
        icon: "😾"
      },
      {
        className: "bg-1f479",
        icon: "👹"
      },
      {
        className: "bg-1f47a",
        icon: "👺"
      }
    ]
  },

];