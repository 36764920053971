<template>
  <div class="login">
    <div class="content">
      <h1>系统登录</h1>
      <!-- 表单 -->
      <div>
        <el-form
          label-width="80px"
          size="medium"
          :model="form"
          :rules="rules"
          ref="ruleForm"
          hide-required-asterisk
        >
          <el-form-item label="用户名" prop="username">
            <el-input v-model="form.username">
              <!-- 输入框前面的图标 -->
              <i slot="prefix" class="iconfont icon-yonghufill"></i>
            </el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="form.password" :type="isShowPWD ? 'text' : 'password'">
              <!-- 输入框前面的图标 -->
              <i slot="prefix" class="iconfont icon-mima"></i>
              <!-- 输入框后面的图标(眼睛) -->
              <i
                slot="suffix"
                :class="[
                'iconfont',
                isShowPWD
                  ? 'icon-yanjing_huaban1'
                  : 'icon-05wangluoanquan_biyanjing',
              ]"
                @click="isShowPWD = !isShowPWD"
              ></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="goLogin" @keyup.enter="keyDown(e)">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { userLogin } from "../../api/user";
export default {
  name: "Login",

  data() {
    return {
      form: {
        username: "",
        password: ""
      },
      isShowPWD: false,
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 1,
            max: 11,
            message: "长度在 1 到 11 个字符",
            trigger: "blur"
          }
        ],

        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      }
    };
  },
  created(){
    this.bus.$emit("quit",true)
  },
  mounted() {
    // 绑定监听事件
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },
  methods: {
    // 登录按钮事件
    goLogin() {
      this.$refs.ruleForm.validate(async val => {
        //val返回true表示验证通过，false表示验证失败
        if (!val) return;
        //验证通过：发送请求
        const { username, password } = this.form;
        let rst = await userLogin({
          username,
          password
        });
        console.log(rst);
        const { code, msg, data } = rst.data;
        if (code !== 1) {
          //请求失败
          this.$message.error(msg);
        } else {
          //请求成功
          this.$message.success(msg);
          localStorage.setItem("token", data.token);
          localStorage.setItem("lastLogin", JSON.stringify(data));
          this.$router.replace("/home");
        }
      });
    },
    // 点击回车键登录
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode == 13 ) {
        this.goLogin(); // 定义的登录方法
      }
    },
  },
  
};
</script>

<style lang="less" scoped>
.login {
  // height: 100%;
  height: calc(100vh);
  background-color: #2d3a4b;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    width: 400px;

    > h1 {
      color: white;
      font-size: 26px;
      font-weight: 500 !important;
      text-align: center;
      margin-bottom: 2em;
    }

    /deep/ .el-form-item__label {
      color: white;
    }

    /deep/ .el-input__inner {
      background-color: #283443;
      color: white;
    }

    .el-button {
      width: 100%;
    }
  }
}
</style>