import Axios from "axios";
// const requests = Axios.create({
//     baseURL: "https://im.yizebom.com/",
//     timeout: 5000,
// })

export function request1(config) {
    // 创建axios实例
    const instance = Axios.create({
        baseURL: "https://im.yizebom.com/",
        timeout: 5000
    })
    // 发送真正的网络请求
    return instance(config)
}


export function request2(config) {
    // 创建axios实例
    const instance = Axios.create({
        baseURL: "https://api.yizebom.com/",
        timeout: 5000
    })

    let token = JSON.parse(localStorage.getItem('user_ID')).token
    instance.defaults.headers["token"] = token;
    instance.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
    instance.defaults.headers["X-Requested-With"] = "xmlhttprequest";
    // 发送真正的网络请求
    return instance(config)

}


// 客服im
export function request3(config) {
    let token = localStorage.getItem('token')
    // 创建axios实例
    const instance = Axios.create({
        baseURL: "https://chat.xuekao123.com",
        // baseURL: "http://im.xuekao123.com",
        timeout: 5000
    })
    instance.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
    instance.defaults.headers["X-Requested-With"] = "xmlhttprequest";
    instance.defaults.headers["token"] = token;
    // 发送真正的网络请求
    return instance(config)

}