// 1.导入axios
import axios from 'axios';
// 设置请求的基础路径
axios.defaults.baseURL = 'https://chat.xuekao123.com/api';
//请求拦截：拦截请求，携带token
axios.interceptors.request.use((config) => {
    config.headers.token = localStorage.getItem('token');
    return config;
}, (err) => {
    return Promise.reject(err);
})
// 响应拦截：拦截响应的数据，只返回需要的那一部分
axios.interceptors.response.use((rst) => {
    //请求成功的回调
    return rst;
}, (err) => {
    // 请求失败的回调
    return Promise.reject(err)
})
// 2.定义请求的函数
// function request(url, data = {}, method = 'get') {
//     return axios({
//         url,
//         method,
//         data: method === 'post' ? data : '',
//         params: method === 'get' ? data : ''
//     })
// }

// 3.导出
export default {
    get(url, data) {
        return axios.get(url, data);
    },
    post(url, data) {
        return axios.post(url, data);
    }
}