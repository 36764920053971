<template>
  <div class="zuimax"> 
  <el-menu
    router
    unique-opened
    :default-active="$route.path"
    class="el-menu-vertical-demo"
    background-color="#304156"
    text-color="#fff"
    active-text-color="#409EFF"
    @select="handleSelect"
  >
    <!-- 标题 -->
    <h2>
      <span class="menu-title"><i class="el-icon-dish"></i></span>
      <span>聊天后台管理</span>
    </h2>
    <!-- 首页 -->
    <el-menu-item index="/home" >
      <i class="iconfont icon-31shouyexuanzhong myicon"></i>
      <span slot="title">聊天</span>
    </el-menu-item>

    <!-- 数据管理-->
    <el-submenu index="/management">
      <template slot="title">
        <i class=" el-icon-setting myicon"></i>
        <span>数据管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/management/project">项目站点管理</el-menu-item>
        <el-menu-item index="/management/region">地区管理</el-menu-item>
        <el-menu-item index="/management/squad">小组管理</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <!-- 访客管理-->
    <el-submenu index="/fangke">
      <template slot="title">
        <i class=" el-icon-user-solid myicon"></i>
        <span>访客管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/fangke/fkmd">访客名单</el-menu-item>
        <el-menu-item index="/fangke/hmd">黑名单</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <!-- 聊天记录-->
    <el-submenu index="/jilu">
      <template slot="title">
        <i class=" el-icon-time myicon"></i>
        <span>记录中心</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/jilu/ltjl">聊天记录</el-menu-item>
        <el-menu-item index="/jilu/leave">留言管理</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <!-- 风格设置-->
    <!-- <el-submenu index="/fengge">
      <template slot="title">
        <i class=" el-icon-s-open myicon"></i>
        <span>风格设置</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/fengge/fkPC">访客端-pc设置</el-menu-item>
        <el-menu-item index="/fengge/fkYD">访客端-移动端</el-menu-item>
      </el-menu-item-group>
    </el-submenu> -->
  </el-menu>
</div>
</template>

<script>
// import bus from '../common/bus';
export default {
  name: "MyMenu",

  data() {
    return {
    };
  },

  mounted() {},

  methods: {
    handleSelect(key, keyPath) {
        this.bus.$emit('block',key)
      }
  },
};
</script>

<style lang="less" scoped>
.zuimax{
  height: calc(100vh);
  background-color: gainsboro;
  z-index: 1;

}
.el-menu {
  height: 100%;
  // border-style: none;
  border-width: 0;
  .myicon {
    margin-right: 10px;
    font-size: 18px;
  }
  .menu-title {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #1296db;
    border-radius: 50%;
    margin-right: 5px;
    color: black;
  }
  h2 {
    color: white;
    height: 3em;
    line-height: 3em;
    padding-left: 20px;
  }
}
::v-deep .el-submenu .is-active{
  font-weight: bolder;
}
</style>