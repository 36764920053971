import {
    request3
} from "./index";

//游客登录
export function ykDl(url) {
    return request3({
        url: '/api/users/userLogin',
        method: 'post',
        data: {
            url: url
        }
    })
}

//分配客服
export function fpKf(data) {
    return request3({
        url: '/api/Chat/assignCustomerService',
        method: 'post',
        data: data
    })
}

//进入房间
export function joinRoom(data) {
    return request3({
        url: '/api/WsApi/joinGroup',
        method: 'post',
        data: data
    })
}

//获取自动回复
export function getAutoreply(data) {
    return request3({
        url: '/api/chat/getAutoreply',
        method: 'post',
        data: data
    })
}


//绑定id
export function bindId(data) {
    return request3({
        url: '/api/WsApi/bindUid',
        method: 'post',
        data: data
    })
}

//发送消息
export function sendMsg(data) {
    return request3({
        url: '/api/WsApi/sendMsg',
        method: 'post',
        data: data
    })
}

// 发送消息之前先把数据传进数据库
export function saveText(data) {
    return request3({
        url: '/api/Savemsg/saveText',
        method: 'post',
        data: data
    })
}

//获取敏感词
export function judgeBadWords(data) {
    return request3({
        url: '/api/chat/getSensitiveWords',
        method: 'post',
        data: data
    })
}

// 获取ip地址
export function getIp() {
    return request3({
        url: '/api/chat/getIp',
        method: 'get'
    })
}

// 获取聊天记录
export function getChatMsg(data) {
    return request3({
        url: '/api/Savemsg/getChatMsg',
        method: 'post',
        data: data
    })
}

// 发送图片
export function saveImg(data) {
    return request3({
        url: '/api/Savemsg/saveImg',
        method: 'post',
        data: data
    })
}

// 客服获取联系人列表
export function getChatList(data) {
    return request3({
        url: '/api/chat/getChatList',
        method: 'post',
        data: data
    })
}

// 修改消息状态
// export function changeRead(data) {
//     return request3({
//         url: '/api/chat/changeRead',
//         method: 'post',
//         data: data
//     })
// }
// 联系人列表
export function getVisitorColumn(data) {
    return request3({
        url: '/api/chat_message/Chat/getVisitorColumn',
        method: 'post',
        data: data
    })
}

// 新-- 客服获取聊天记录
export function getAdminList(data) {
    return request3({
        url: '/api/chat_message/Chat/getAdminList',
        method: 'post',
        data: data
    })
}
// // 新-- 客服获取聊天记录
// export function getSiteTags(data) {
//     return request3({
//         url: '/api/site/setting/getSiteTags',
//         method: 'post',
//         data: data
//     })
// }
// 新-- 客服获取快捷回复
export function getAdminTags(data) {
    return request3({
        url: '/api/site/setting/getAdminTags',
        method: 'post',
        data: data
    })
}
// 存图片
export function saveImgs(data) {
    return request3({
        url: '/api/chat/saveImg',
        method: 'post',
        data: data
    })
}
// 撤回
export function withdrawl(data) {
    return request3({
        url: '/api/record/chat/withdrawl',
        method: 'post',
        data: data
    })
}
// 消息变为已读
export function changeRead(data) {
    return request3({
        url: `/api/record/chat/changeRead`,
        method: 'POST',
        data: data
    })
}
// 解散房间
export function disbandedRoom(data) {
    return request3({
        url: `/api/WsApi/disbandedRoom`,
        method: 'POST',
        data: data
    })
}