import {
    request1,
    request2
} from "./index";

// 获取联系人列表
export function contactList(data) {
    return request1({
        url: 'im/chat/queryMsgNotice',
        method: 'post',
        data: data
    })
}

// 拉取聊天记录
export function messageList(data) {
    return request1({
        url: 'im/chat/queryAllChatMsg',
        method: 'post',
        data: data
    })
}

// 发送图片
export function sendImage(data) {
    return request2({
        url: 'app/file/uploadPhoto.todo',
        method: 'post',
        data: data
    })
}

// 请求交换手机号
export function exchangePhone(data) {
    return request1({
        url: 'im/chat/fillPhoneOrWx',
        method: 'post',
        data: data
    })
}

//拒绝交换手机号
export function rejectPhones(data) {
    return request1({
        url: 'im/chat/changeMsgStateById',
        method: 'post',
        data: data
    })
}

//收藏岗位
export function collect(data) {
    return request2({
        url: 'app/collect/insert.todo',
        method: 'post',
        data: data
    })
}

// 拉取职位信息
export function getPosition(data) {
    return request2({
        url: `app/jobs/selectJobsAppById.todo?userId=${data.userId}&jobsId=${data.jobsId}`,
        method: 'get',
    })
}
