<template>
  <div class="layout">
    <el-container>
      <el-aside width="200px">
        <!-- 左侧tab区域 -->
        <MyMenu/>
      </el-aside>
      <!-- 右侧主体区域 -->
      <el-container>
        <el-header>
          <MyHeader/>
        </el-header>
        <el-main class="content">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import MyMenu from "../../components/MyMenu.vue";
import MyHeader from "../../components/MyHeader.vue";
export default {
  name: "Layout",
  components: {
    MyMenu,
    MyHeader
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {}
};
</script>

<style lang="less" scoped>
.layout {
  height: 100%;
  .el-container {
    height: 100%;
    .el-header{
      background-color: #f1f4f6;
    }
  }
}
.content {
  padding: 0px !important;
}
// .is-active {
//   background-color: red !important;
// }
</style>