<template>
  <div id="ServiceView">
    <audio
      muted
      :src="playSrc"
      style="display: none"
      controls="controls"
      ref="audio"
    ></audio>
    <div
      class="dialog_wrapper"
      style="position: fixed; z-index: 99"
      v-bind:style="styleObject"
      v-show="isShow"
    >
      <div class="fuz" @click="copyText">复制文字</div>
    </div>
    <div
      class="dialog_wrapper"
      style="position: fixed; z-index: 99"
      v-bind:style="styleObject"
      v-show="isShow2"
    >
      <div class="fuz" @click="copyText">复制文字</div>
      <div class="fuz" @click="revocation">撤回消息</div>
    </div>
    <div
      class="dialog_wrapper"
      style="position: fixed; z-index: 99"
      v-bind:style="styleObject"
      v-show="imgshow"
    >
      <div class="fuz" @click="downloadIamge">下载图片</div>
      <div class="fuz" @click="revocation">撤回消息</div>
    </div>
    <div
      class="dialog_wrapper"
      style="position: fixed; z-index: 99"
      v-bind:style="styleObject"
      v-show="imgshow2"
    >
      <div class="fuz" @click="downloadIamge">下载图片</div>
    </div>
    <div class="keCenter" v-if="!this.visitors.visitor_id">
      <i class="el-icon-chat-dot-square icons" size="500"></i>
      <p><b>客服中心</b></p>
    </div>
    <div v-else>
      <div
        class="message gdt"
        ref="scrollview"
        :style="{ height: isFast == true ? height : '470px' }"
      >
        <ul @mousewheel="scrollChange">
          <div v-if="isLodding2" class="loading">
            <span></span>
          </div>
          <li
            v-for="(val, index) in messageList"
            :key="index"
            :class="val.send_id == 'kf' ? 'he' : 'my'"
          >
            <!-- 头像 -->
            <img
              class="avatar"
              :src="
                val.send_id == 'kf'
                  ? 'https://chat.xuekao123.com/uploads/img/adminImg.webp'
                  : 'https://chat.xuekao123.com/uploads/img/userImg.png'
              "
              alt=""
              v-show="val.type != 2"
            />
            <div
              class="yangshi"
              style="display: flex; flex-direction: column; margin-top: -6px"
              v-show="val.msg_type != 3"
              v-if="val"
            >
              <div class="timer" v-show="val.createtime && val.type != 2">
                {{ val.createtime | formatDate("-") }}
              </div>
              <div
                class="content messagess"
                v-if="val.msg_type == '0'"
                @contextmenu.prevent.stop="rightClick($event, val, index)"
              >
                <span style="white-space: pre-line">{{ val.mes }}</span>
              </div>
              <!-- 图片消息 -->
              <img
                class="messageimg"
                v-else-if="val.msg_type == '1'"
                :src="imgUrl + val.mes"
                alt=""
                @contextmenu.prevent.stop="rightClick2($event, val, index)"
                @click="handleMessageClick(val.mes)"
              />
            </div>
            <div v-if="val.type == 2" class="chehui_msg">你撤回一条消息</div>
          </li>
        </ul>
      </div>

      <!-- 消息发送 -->
      <div v-show="this.visitors.online == '1'" class="send_message">
        <!-- 快捷用语 -->
        <div v-show="isFast" class="quick">
          <div class="xiaoxi">
            <span
              v-for="(item, index) in fastMsg"
              :key="index"
              @click="sendQuickMsg(item)"
              >{{ item }}</span
            >
          </div>
          <div class="off" @click="closeFastMsg">
            <i class="el-icon-close" style="color: #b6b6b6"></i>
          </div>
        </div>
        <!-- 表情简历等 -->
        <div class="biaoqiang">
          <div class="emjoi">
            <img
              class="emjoi2"
              src="../assets/images/emjoi.png"
              alt=""
              @click.stop="emjoiShow = !emjoiShow"
            />
          </div>
          <transition name="fade">
            <emjoi class="emjoi_view" v-show="emjoiShow"></emjoi>
          </transition>

          <div class="photo" @click="getFile">
            <img src="../assets/images/photo.png" alt="" />
            <input
              type="file"
              ref="file"
              accept="image/*"
              style="display: none"
              v-on:change="handleFileUpload($event)"
            />
          </div>
          <input
            type="file"
            ref="file"
            accept="image/*"
            style="display: none"
            v-on:change="handleFileUpload($event)"
          />
        </div>
        <!-- 输入框 -->
        <div class="text">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请简单描述您的问题"
            v-model="textarea"
            class="sd"
            autofocus="true"
            @keydown.native="handleKeyCode($event)"
          >
            <!-- @keyup.enter.native="sendMessage" -->
          </el-input>
        </div>
        <div class="send">
          <button @click="overChat">结束</button>
          <button @click="sendMsg">发送</button>
        </div>
      </div>
    </div>
    <!-- 图片弹窗 -->
    <el-dialog
      :visible.sync="imgDialog"
      :before-close="handleImgClose"
      top="100px"
      class="imgpopup"
    >
      <img :src="imgTcUrl" class="videoTc" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import {
  ykDl,
  fpKf,
  bindId,
  getIp,
  joinRoom,
  sendMsg,
  saveText,
  getChatMsg,
  saveImg,
  getChatList,
  changeRead,
  getVisitorColumn,
  getAdminList,
  getAdminTags,
  saveImgs,
  withdrawl,
  disbandedRoom,
} from "../api/xkw";
import emjoi from "../components/emjoi.vue";
export default {
  name: "MyproServiceView",
  data() {
    return {
      height: "420px",
      url: "", //当前站点url
      ws: null,
      wsUrl: "wss://chat.xuekao123.com/wss",
      // wsUrl: "ws://118.31.117.110:8282",
      imgUrl: "http://chat.xuekao123.com",
      avatarUrl: "https://s1.s.360xkw.com/",
      user: {}, //游客的id数据
      userInfo: {},
      kefu: {}, //客服的数据
      ip: {}, // 后台获取用户的ip
      host: "", //用户咨询的网址
      kfId: "", //客服id
      messageList: [], // 聊天记录
      isFast: true, //展示快捷消息
      fastMsg: [], //快捷消息
      textarea: "", //发送文本消息
      emjoiShow: false,
      client_id: "",
      pageSize: 1, //分页页码
      listRows: 20, //每页条数
      isLodding: true,
      isLodding2: false,
      isLodding3: true,
      imgshow2: false,
      isShow: false,
      isShow2: false,
      revocationObj: {},
      styleObject: {
        top: "0",
        left: "0",
      },
      isChehui: null,
      imgshow: false,
      imgDialog: false,
      imgTcUrl: "",
      lastLogin: {},
      visitors: {
        // id: 1,
      }, //游客数据
      times: "",
      historList: [],
      playSrc: "https://chat.xuekao123.com/assets/music/messageTone.mp3",
    };
  },
  components: {
    emjoi,
  },
  beforeCreate() {
    this.bus.$on("emjois", (msg) => {
      this.textarea = this.textarea + msg;
    });
    this.bus.$on("youke", (msg) => {
      this.visitors = msg;
      localStorage.setItem("visitors", JSON.stringify(msg));
    });
  },
  async mounted() {
    this.lastLogin = JSON.parse(localStorage.getItem("lastLogin"));
    this.webSocketInit();
    // this.visitors = JSON.parse(localStorage.getItem("visitors"));
    let datas = {
      admin_id: this.lastLogin.id,
    };
    let quick = await getAdminTags(datas);

    if (quick.data.code == 200) {
      this.fastMsg = quick.data.data;
    }
    if (this.fastMsg.length == 0) {
      this.isFast = false;
    }
    this.getTime();
    // if (this.visitors.visitor_id) {
    //   this.handlePullMessages();
    //   console.log(this.messageList, "----------------");
    // }
    document.addEventListener("click", (e) => {
      // console.log(e.target.className);
      if (e.target.className !== "emjoi2" || e.target.className !== "emjoi3") {
        this.emjoiShow = false;
      }
      if (e.target.className !== "messagess") {
        this.isShow = false;
        this.isShow2 = false;
        this.imgshow = false;
        this.imgshow2 = false;
        // this.isResume = false;
      }
    });

    let _this = this;
    // 判断用退出登录   断开WebSocket
    this.bus.$on("quit", (val) => {
      if (val) {
        _this.ws.close();
      }
    });
  },

  methods: {
    // 分配客服，查询客服是否在线
    async goChat() {
      let that = this;
      var myId = ""; //用户id
      // 假定 随机游客ID
      myId = parseInt(Math.random() * (9999 - 1000 + 1) + 1000);
      // let url = "baidu.com";
      this.url = "im1.xuekao123.com";
      let user = await ykDl(this.url);
      // console.log(user, "---------");
      if (user.data.code == 200) {
        that.user = user.data.data;
        localStorage.setItem("user", JSON.stringify(user.data.data));
        let data = {
          url: this.url,
          toid: user.data.data.id,
        };
        let res = await fpKf(data);
        // console.log(res);
        if (res.data.code == 203) {
          alert("暂无客服在线，将转到留言板");
        } else {
          this.kefu = res.data.data;
          localStorage.setItem("kfId", JSON.stringify(res.data.data));
        }
      }
    },
    // 链接开始聊天
    webSocketInit() {
      let that = this;
      this.ws = new WebSocket(this.wsUrl);

      // 首次链接
      this.ws.onopen = function (e) {
        console.log(e, "链接成功");
        that.heartbeat();
      };
      // 收到消息
      this.ws.onmessage = async function (e) {
        let data = JSON.parse(e.data);
        console.log(data, "收到的信息99999999");

        // console.log(that.visitors.id, "当前聊天人的信息");
        // init为初始化
        if (data.type == "init") {
          that.client_id = data.client_id;
          that.bindId(data.client_id);
        } else if (data.type == "creat" && data.ttttt == "加入房间") {
          that.$message({
            message: `游客${data.client_id}进入聊天室`,
            type: "success",
          });
          let a = { content: {} };

          a.visitor_id = data.client_id;
          a.createtime = that.times;
          a.content.type = "3";
          a.online = 1;
          a.no_read_count = 0;
          let contactsList = [];
          contactsList.push(a);
          // console.log(contactsList, 9999999999999999);
          that.bus.$emit("onLine", contactsList);
          // that.getContactList();
        } else if (data.type == "unsetRoom") {
          that.$message.error(`游客${data.roomId}离开聊天室`);
          let a = {
            id: data.roomId,
            visitor_id: data.roomId,
            createtime: that.times,
          };

          that.bus.$emit("offLines", a);
          // that.getContactList();
        } else if (data.type == "msg") {
          let i = 0;
          if (data.type == "msg" && data.status == 2) {
            data.createtime = Math.round(
              new Date().getTime() / 1000
            ).toString();
            data.send_id = "kf";
            data.mes = data.message;
            that.historList.push(data);
            that.bus.$emit("kfSend", data);
          } else if (data.type == "msg" && data.status == 1) {
            data.send_id = "user";
            data.mes = data.message;
            data.createtime = Math.round(
              new Date().getTime() / 1000
            ).toString();
            i++;
            let a = {
              read: i,
              id: data.userId,
              mes: data.message,
              type: data.msg_type,
            };

            that.bus.$emit("noRead", a);
            // console.log(a, "计数");
            that.historList.push(data);
            that.play();
            if (data.messages.reply) {
              that.autoResponse(data.messages.reply,data.messages.myid);
            }
          }
          if (Number(data.userId) == Number(that.visitors.visitor_id)) {
            data.createtime = Math.round(
              new Date().getTime() / 1000
            ).toString();

            let a = {
              id: data.userId,
            };
            that.bus.$emit("isRead", a);
            // console.log(data.userId, that.visitors.id, "收到消息");
            let reads = {
              admin_id: that.lastLogin.id,
              visitor_id: that.visitors.visitor_id,
            };
            let read = await changeRead(reads);
            // console.log(read, "收到消息清空回调");

            if (data.type == "msg" && data.status == 2) {
              data.send_id = "kf";
              data.mes = data.message;
              that.messageList.push(data);
              that.scrollToBottom();
            } else if (data.type == "msg" && data.status == 1) {
              data.send_id = "user";
              data.mes = data.message;
              that.messageList.push(data);
              that.scrollToBottom();
            }
          }
        }
      };
    },
    // 发送心跳
    heartbeat() {
      setInterval(() => {
        this.ws.send('{ "type": "ping" }');
      }, 3000);
    },
    // 绑定uid
    async bindId(cid) {
      // console.log(this.user);
      let data = {
        client_id: cid,
        message: {
          type: "bind",
          myid: "user" + this.lastLogin.id,
        },
      };
      let a = await bindId(data);
      // console.log(a, "绑定id成功");
      this.getContactList();
    },

    // 获取消息列表
    async getContactList() {
      let data = {
        // userId:
        admin_id: this.lastLogin.id,
        page: 1,
        limit: 30,
      };
      let res = await getVisitorColumn(data);
      // console.log(res, "拉取消息列表");
      if (res.data.code == 200) {
        let list = res.data.data;
        // console.log(list, 999999999999999999);
        // console.log(list, 99999999);
        let onLine = [];
        let offLine = [];
        for (let i = 0; i < list.length; i++) {
          if (list[i].type == null) {
            list[i].type = 1;
          }
          // const element = array[i];
          if (list[i].online == 1 && list[i].status == 0) {
            onLine.push(list[i]);
          } else {
            offLine.push(list[i]);
          }
        }
        this.bus.$emit("list", onLine);
        this.bus.$emit("list2", offLine);
      }
    },
    // 分配客服拉进房间
    async kfRoom(cid) {
      let roomData = {
        client_id: cid,
        message: {
          type: "join",
          toid: this.kefu.data,
          myid: this.user.id,
        },
      };
      // console.log(roomData);
      let a = await joinRoom(roomData);
      // console.log("加入房间", a);
    },

    // 拉取客服聊天记录
    async handlePullMessages() {
      this.messageList = [];
      let data = {
        // user: this.lastLogin.id,
        // toid: this.visitors.visitor_id,
        visitor_id: this.visitors.visitor_id,
        admin_id: this.lastLogin.id,
        page: this.pageSize,
        limit: this.listRows,
      };
      let a = await getAdminList(data);
      // console.log(a, "拉取聊天记录");
      if (a.data.data.length != 0) {
        let list = a.data.data[0].content;

        for (let i = 0; i < list.length; i++) {
          if (list[i].role == "admin") {
            list[i].send_id = "kf";
          }

          list[i].mes = list[i].content;
          list[i].msg_type = list[i].type;
        }
        this.messageList.push(...list);
        this.messageList = this.messageList.reverse();
        this.scrollToBottom();
      }
    },

    // 滚动监听 --- 滑动到顶请求数据
    async scrollChange() {
      let that = this;
      const e = this.$refs["scrollview"];
      const scrollTop = e.scrollTop;
      if (scrollTop < 20 && this.isLodding3) {
        that.isLodding3 = false;
        if (this.isLodding) {
          this.isLodding2 = true;
          setTimeout(async () => {
            this.isLodding = false;
            this.pageSize += 1;
            let data = {
              visitor_id: this.visitors.visitor_id,
              admin_id: this.lastLogin.id,
              page: this.pageSize,
              limit: this.listRows,
            };
            let a = await getAdminList(data);
            if (a.data.code == 200 && a.data.data[0].content != 0) {
              let list = a.data.data[0].content;

              for (let i = 0; i < list.length; i++) {
                if (list[i].role == "admin") {
                  list[i].send_id = "kf";
                }

                list[i].mes = list[i].content;
                list[i].msg_type = list[i].type;
              }
              this.messageList.unshift(...list.reverse());

              // console.log(this.messageList, "---------");
              const el = this.$refs["scrollview"];
              this.scrollHeight = el ? el.scrollHeight : 0;
              this.isLodding2 = false;
              setTimeout(() => {
                const currScrollHeight = el.scrollHeight;
                el.scrollTo(0, currScrollHeight - this.scrollHeight);
                this.isLodding = true;
                this.isLodding3 = true;
              }, 50);
            } else {
              this.$message({
                message: "没有更多消息了",
                type: "warning",
              });
              this.isLodding = false;
              this.isLodding2 = false;
              return false;
            }
          }, 1000);
        }
      }
    },
    play() {
      this.$refs.audio.play();
    },
    // 发送消息
    async sendMsg() {
      let that = this;
      this.textarea = this.textarea.replace(/^\s*|\s*$/g, "");
      if (this.textarea.length == 0) {
        this.$message.error("不能发送空消息");
        return false;
      }
      // 存进数据库的数据对象
      let text = {
        user: this.lastLogin.id,
        toid: this.visitors.id,
        mes: this.textarea,
        send_id: "kf",
        addtime: Math.round(new Date().getTime() / 1000).toString(),
      };
      // let saveTexts = await saveText(text);

      // if (saveTexts.data.code == 200) {
      // 发送消息数据对象
      let msg = {
        // client_id: that.client_id,
        message: {
          type: "admin-user",
          toid: this.visitors.visitor_id,
          content: that.textarea,
          myid: this.lastLogin.id,
          msg_type: "0",
          // mes_id: saveTexts.data.data,
        },
      };
      let sendmsg = await sendMsg(msg);
      if (sendmsg.data.code == 200) {
        that.textarea = "";
        this.scrollToBottom();
      } else {
        that.textarea = "";
        this.$message.error("对方已离线");
      }
      // console.log(sendmsg, "发送消息的回调");
      // }
      // console.log(saveTexts);
    },
    // 表情选择
    emjoiSelect() {
      this.emjoiShow = !this.emjoiShow;
    },
    // 首次加载定位到底部
    scrollToBottom() {
      setTimeout(() => {
        this.$nextTick(() => {
          var container = document.querySelector(".gdt");
          container.scrollTop = container.scrollHeight;
        });
      }, 50);
    },
    // 回车发送
    handleKeyCode(e) {
      if ((e.which == 13 && e.ctrlKey) || (e.which == 10 && e.ctrlKey)) {
        // 这里实现换行
        this.textarea = this.textarea + "\n";
      } else if (e.which == 13) {
        // 避免回车键换行
        e.preventDefault();
        this.sendMsg();
        //执行你想要的回车操作
      }
    },
    // 发送图片
    // 获取文件
    getFile() {
      this.$refs.file.click();
    },
    // 获取文件
    async handleFileUpload(event) {
      // 阻止发生默认行为
      let that = this;
      // 阻止发生默认行为
      event.preventDefault();
      let formData = new FormData();
      let file = this.$refs.file.files[0];
      formData.append("img", file);
      formData.append("user", this.lastLogin.id);
      formData.append("toid", this.visitors.visitor_id);
      formData.append("send_id", "user");

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async function () {
        // 获取Data URL的长度
        const size = reader.result.length;
        const maxSize = 1024 * 1024 * 10; // 最大允许上传1MB的图片
        if (size > maxSize) {
          that.$refs.file.value = "";
          alert("文件过大，请上传大小不超过10MB的图片！");
          return;
        } else {
          let a = await saveImgs(formData);
          // console.log(a, 999999999999);
          if (a.data.code == 200) {
            let msg = {
              message: {
                type: "admin-user",
                toid: that.visitors.visitor_id,
                content: a.data.imgPath,
                myid: that.lastLogin.id,
                msg_type: "1",
              },
            };
            let send = await sendMsg(msg);
            if (send.data.code == 206) {
              that.$message({
                type: "error",
                message: "客服不在线!",
              });
            }
            that.$refs.file.value = "";
            // console.log(send);
          } else {
            that.$message.error(a.data.error);
          }
        }
      };
    },
    // 消息右击
    rightClick(event, e, index) {
      if (e.send_id == "kf") {
        this.isShow2 = !this.isShow2;
        this.revocationObj = e;
        this.isChehui = index;
        let x = event.pageX;
        let y = event.pageY;
        this.styleObject.left = x + "px";
        this.styleObject.top = y + "px";
      } else {
        this.isShow = !this.isShow;
        this.revocationObj = e;
        // console.log(e);
        this.isChehui = index;
        let x = event.pageX;
        let y = event.pageY;
        this.styleObject.left = x + "px";
        this.styleObject.top = y + "px";
      }
    },
    // 复制文字
    copyText() {
      var input = document.createElement("input"); // 创建input对象
      input.value = this.revocationObj.mes; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
    },
    // 撤回
    async revocation() {
      this.isShow2 = false;
      let a = this.$options.filters["formatDate"](
        this.revocationObj.createtime
      );
      // console.log(new Date().getTime() - Date.parse(a),2 * 60 * 1000 + 500)
      // console.log(a, this.revocationObj)
      if (new Date().getTime() - Date.parse(a) > 2 * 60 * 1000 + 500) {
        this.$message.error("消息时间已超过2分钟!!!");
        return false;
      } else {
        let data = {
          admin_id: this.lastLogin.id,
          visitor_id: this.visitors.visitor_id,
          message_id: this.revocationObj.message_id,
        };

        let a = await withdrawl(data);
        if (a.data.code == 200) {
          // console.log(this.isChehui, 99);
          this.messageList[this.isChehui].type = 2;
          this.messageList[this.isChehui].msg_type = 2;
        }
      }
    },
    async revocationIamge() {},
    // 图片右击
    rightClick2(event, e, index) {
      if (e.send_id == "kf") {
        this.imgshow = !this.imgshow;
        this.revocationObj = e;
        this.isChehui = index;
        let x = event.pageX;
        let y = event.pageY;
        this.styleObject.left = x + "px";
        this.styleObject.top = y + "px";
      } else {
        this.imgshow2 = !this.imgshow;
        this.revocationObj = e;
        this.isChehui = index;
        let x = event.pageX;
        let y = event.pageY;
        this.styleObject.left = x + "px";
        this.styleObject.top = y + "px";
      }
    },
    // 下载图片
    downloadIamge(imgsrc, name) {
      // console.log(this.revocationObj);
      // 下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); // 得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = this.imgUrl + this.revocationObj.mes;
    },
    // 图片弹窗
    handleImgClose() {
      this.imgDialog = false;
    },
    // 点击图片
    handleMessageClick(val) {
      // if (message.type == "image") {
      this.imgTcUrl = this.imgUrl + val;
      this.imgDialog = true;
      // this.display();
      // }
    },
    // 发送快捷消息
    async sendQuickMsg(val) {
      let that = this;

      // 存进数据库的数据对象
      let text = {
        user: this.kefu.data,
        toid: this.user.id,
        mes: val,
        send_id: "user",
        addtime: Math.round(new Date().getTime() / 1000).toString(),
      };
      // let saveTexts = await saveText(text);
      // if (saveTexts.data.code == 200) {
      // 发送消息数据对象
      let msg = {
        message: {
          type: "admin-user",
          toid: this.visitors.visitor_id,
          content: val,
          myid: this.lastLogin.id,
          msg_type: 0,
          // mes_id: saveTexts.data.data,
        },
      };
      let sendmsg = await sendMsg(msg);
      if (sendmsg.data.code == 200) {
        this.scrollToBottom();
      }
      // console.log(sendmsg, "发送消息的回调");
      // }
    }, // 自动回复
    async autoResponse(val, toid) {
      let that = this;
      let msg = {
        message: {
          type: "admin-user",
          toid: toid,
          content: val,
          myid: this.lastLogin.id,
          msg_type: 0, 
        },
      };

      let sendmsg = await sendMsg(msg); 
    },
    // 关闭快捷用语
    closeFastMsg() {
      this.isFast = false;
    },
    // 获取时间
    getTime() {
      var date = new Date();
      var seperator1 = "-";
      var seperator2 = ":";
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate =
        date.getFullYear() +
        seperator1 +
        month +
        seperator1 +
        strDate +
        " " +
        date.getHours() +
        seperator2 +
        date.getMinutes();
      this.times = currentdate;
    },
    // 解散房间
    async overChat() {
      let that = this;
      let data = {
        roomId: this.visitors.visitor_id,
      };
      // let a = await disbandedRoom(data);
      // console.log(a, "界山房间");
      this.$confirm("确定结束对话?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let a = await disbandedRoom(data);
          // console.log(that.visitors, "结束数组");
          if (a.data.code == 200) {
            let a = {
              id: that.visitors.visitor_id,
              visitor_id: that.visitors.visitor_id,
              createtime: that.times,
            };
            that.visitors.online = 0;
            // console.log(that.visitors, "结束数组");
            that.bus.$emit("offLines", a);
            this.$message({
              type: "success",
              message: "已结束对话!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  watch: {
    visitors: {
      async handler(nVal, oVal) {
        if (nVal) {
          this.pageSize = 1;
          this.messageList = [];
          this.scrollToBottom();
          await this.handlePullMessages();
          // this.messageList.push(...this.historList);
          if (nVal.isOff == false) {
            for (let i = 0; i < this.historList.length; i++) {
              if (nVal.visitor_id == this.historList[i].userId) {
                this.messageList.push(this.historList[i]);
              }
              let forData = [];
              this.messageList.forEach((item) => {
                if (!forData.some((e) => e.message_id == item.message_id))
                  forData.push(item);
              });
              this.messageList = forData;
            }
          }

          this.isLodding = true;
          this.isLodding3 = true;
          if (this.fastMsg.length == 0) {
            this.isFast = false;
          } else {
            this.isFast = true;
          }

          if (nVal.online == 0) {
            this.height = "620px";
          } else if (nVal.online == 1) {
            this.height = "420px";
          }
        }
      },
    },
    isFast: {
      handler(nVal, oVal) {
        if (nVal == false) {
          this.height = "470px";
        }
      },
    },
  },
  filters: {
    formatDate(value) {
      if (!isNaN(value)) {
        let date = new Date(value * 1000);
        //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM; //月补0
        let d = date.getDate();
        d = d < 10 ? "0" + d : d; //天补0
        let h = date.getHours();
        h = h < 10 ? "0" + h : h; //小时补0
        let m = date.getMinutes();
        m = m < 10 ? "0" + m : m; //分钟补0
        let s = date.getSeconds();
        s = s < 10 ? "0" + s : s; //秒补0
        // return y + "-" + MM + "-" + d; //年月日
        return y + "-" + MM + "-" + d + " " + h + ":" + m; //年月日时分秒
      } else {
        // console.log(dateStr.replace(/-/gi,"/"))
        return value;
      }
    },
  },
  beforeDestroy() {
    // 获取指定元素
    const scrollview = this.$refs["scrollview"];
    // 移除监听
    // scrollview.removeEventListener("scroll", this.scrollChange, true);
    window.removeEventListener("click", () => {}, true);
    localStorage.setItem("isKefu", false);
  },
};
</script>

<style lang="less" scoped>
#ServiceView {
  width: 500px;
  height: 648px;
  background: #fff;
  border-right: 1px solid #ebeff4;
  border-radius: 0px 0px 0px 10px;
  box-sizing: border-box;
  .keCenter {
    height: 648px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icons {
      font-size: 84px;
      color: #e6e6e6;
    }
    p {
      font-size: 18px;
      color: #ddd;
    }
  }

  .loading {
    text-align: center;
  }
  .loading span {
    display: inline-block;
    width: 10px;
    margin: 0 auto;
    height: 10px;
    border: 2px solid #409eff;
    border-left: transparent;
    animation: zhuan 0.5s linear infinite;
    border-radius: 50%;
  }
  @keyframes zhuan {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .message {
    max-height: 620px;
    // padding: 10px 21px;
    box-sizing: border-box;
    height: 420px;
    // overflow: hidden;
    overflow-y: scroll;

    > ul {
      // max-height: 420px;
      // height: 370px;

      padding: 20px 21px 0;
      box-sizing: border-box;
    }
    li {
      margin-bottom: 20px;
      display: flex;
      .avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        vertical-align: middle;
      }
      .chehui_msg {
        background: #e9e9e9;
        color: #aaa;
        font-size: 12px;
        margin: 0 auto;
        padding: 5px 10px;
        border-radius: 4px;
      }
      .timer {
        color: #aaa;
        font-size: 12px;
      }
      .content {
        display: inline-block;
        max-width: 260px;
        min-height: 30px;
        padding: 8px 12px;
        box-sizing: border-box;
        background: #eef2f7;
        border-radius: 4px;
        font-size: 14px;
        font-family: Source Han Serif CN;
        font-weight: 500;
        color: #333333;
        position: relative;
        margin-left: 15px;
        word-break: break-all;
        line-break: anywhere;
        span {
          letter-spacing: 1px;
          line-height: 17px;
        }
      }
      .messageimg {
        margin: 0 10px;
        max-width: 260px;
        max-height: 100px;
      }
      .jiaohuan {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        p {
          max-width: 260px;
          height: 38px;
          box-sizing: border-box;
          border-top: 0.5px solid #ccc;
          margin-top: 5px;
          line-height: 38px;
          display: flex;
          // justify-content: space-evenly;
          text-align: center;
          span {
            flex: 1;
            cursor: pointer;
          }
          span:nth-child(2) {
            color: #0362d2;
          }
        }
      }
    }
    .my {
      justify-content: flex-start;
      .content::after {
        content: "";
        position: absolute;
        // right: -100px;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        left: -7px;
        top: 5px;
        border-left: none;
        border-right-color: #eef2f7;
      }
      .yangshi {
        align-items: flex-start;
      }
      .timer {
        margin-left: 15px;
        // margin-top: -16px;
        margin-bottom: 5px;
      }
    }
    .he {
      // justify-content: flex-end;
      display: flex;
      flex-direction: row-reverse;
      img {
        float: right;
        margin-left: 15px;
      }
      .content::after {
        content: " ";
        position: absolute;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        top: 5px;
        right: -7px;
        border-right: none;
        border-left-color: #eef2f7;
      }
      .yangshi {
        align-items: flex-end;
      }
      .timer {
        // margin-top: -16px;
        margin-bottom: 5px;
      }
    }
    .jhsj {
      width: 100%;
      margin-top: 13px;
    }
  }
  .quick {
    width: 500px;
    height: 50px;
    padding: 0 19px 0 15px;
    box-sizing: border-box;
    background: #f3f3f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .xiaoxi {
      max-width: 430px;
      overflow-x: scroll;
      height: 50px;
      line-height: 47px;
      overflow-y: visible;
      white-space: nowrap;

      span {
        display: inline-block;
        height: 26px;
        padding: 0 17px;
        background: #ffffff;
        border: 1px solid #0362d2;
        border-radius: 13px;
        color: #0362d2;
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        margin-right: 26px;
        cursor: pointer;
      }
      span:last-child {
        margin-right: 0;
      }
    }
  }
  .send_message {
    .biaoqiang {
      display: flex;
      align-items: center;
      height: 50px;
      position: relative;
      .emjoi_view {
        position: absolute;
        z-index: 99;
        left: -15px;
        bottom: 50px;
      }
      > div {
        margin-left: 16px;
      }
      .resume {
        height: 25px;
        padding: 0 12px;
        background: #ffffff;
        border: 1px solid #dedede;
        font-size: 14px;
        font-family: Source Han Serif CN;
        font-weight: 500;
        color: #454545;
        line-height: 25px;
        cursor: pointer;
      }
    }
    .text {
      div textarea.el-textarea__inner {
        border: none !important;
        resize: none !important;
        /* 去除边框 */
        border: none;
        resize: none;
        /* 去除选中后的边框 */
        outline: none;
      }
    }
    .send {
      margin-top: 15px;
      text-align: right;
      padding-right: 16px;
      box-sizing: border-box;
      button {
        width: 70px;
        height: 30px;
        border-radius: 2px;
        border: none;
        font-size: 14px;
        color: #989898;
        font-weight: 500;
      }
      button:nth-child(2) {
        background: #0362d2;
        color: #ffffff;
        margin-left: 15px;
      }
    }
  }
  .dialog_wrapper {
    min-width: 120px;
    font-size: 14px;
    line-height: 16px;
    color: #000;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 6%);
    z-index: 9999;
    background-color: #fff;
    .fuz {
      padding: 10px 15px;
    }
    .fuz:hover {
      background-color: #f3f3f3;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 3px;
  background-color: #f9f9f9;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #ccc;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 4px;
  height: 3px;
  background-color: #f9f9f9;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #ccc;
}
.videoTc {
  max-width: 80%;
}
.sd ::v-deep .el-textarea__inner {
  /* 去除边框 */
  border: none !important;
  resize: none !important;
  /* 去除选中后的边框 */
  outline: none !important;
}
.sd ::v-deep .el-textarea__inner::-webkit-scrollbar {
  width: 0 !important;
}
.sd ::v-deep .el-textarea__inner::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.imgpopup ::v-deep .el-dialog .el-dialog__body {
  text-align: center;
  padding: 30px 0px !important;
}
</style>

