<template>
  <div id="now">
    <div class="block">
      <el-carousel trigger="click" height="400px" class="lunbo">
        <el-carousel-item>
          <img
            style="width: 100%; height: 100%"
            src="https://s1.s.360xkw.com/s1s/img/166547134773027.png"
            alt=""
          />
        </el-carousel-item>
        <el-carousel-item>
          <img
            style="width: 100%; height: 100%"
            src="	https://www.jxrszp.com/uploadfile/2023/0211/1676083354482515.jpg"
            alt=""
          />
        </el-carousel-item>
        <el-carousel-item>
          <img
            style="width: 100%; height: 100%"
            src="https://www.jxrszp.com/uploadfile/2023/0210/1675995793202399.jpg"
            alt=""
          />
        </el-carousel-item>
        <el-carousel-item>
          <img
            style="width: 100%; height: 100%"
            src="https://www.ahrczp.net/uploadfile/2023/0105/20230105101418253.jpg"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="description">
      广东人才招聘网是易职邦旗下广东招聘网站，致力于打造人才技能+招聘就业的综合性广东人才市场服务平台。截止目前广东省人才网遍布全国23个省份、4个直辖市;秉承服务于天下有发展需求的人!
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
#now {
  .block {
    margin-top: 20px;
    .lunbo {
     ::v-deep .el-carousel__indicators{
        .el-carousel__indicator{
          .el-carousel__button{
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
        }
      }
    }
  }
  .description {
    margin-top: 24px;
    padding: 0 22px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>