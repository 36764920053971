<template>
  <div id="contacts">
    <ul class="contacts" ref="scrollview" @scroll="handleScroll">
      <li
        v-for="(item, index) in list"
        :key="index"
        :class="isactive == index ? 'addclass' : ''"
        @click="goChat(index, item)"
      >
        <div class="avatar">
          <img src="https://chat.xuekao123.com/uploads/img/userImg.png" alt="" />
          <span class="no_read" v-show="item.noReadNum">{{
            item.noReadNum
          }}</span>
        </div>
        <div class="message">
          <p>
            <span>
              <span class="zaixian">离线</span>
              游客 {{ item.visitor_id }}
            </span>
            <span>{{ item.createtime | formatDate("-") }}</span>
          </p>
          <p>点击查看历史消息</p>
          <!-- <p v-if="item.content.type == '1'">[图片]</p> -->
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { contactList } from "../api/yzb";
import { getChatList, getVisitorColumn } from "../api/xkw";
export default {
  data() {
    return {
      isactive: -1,
      list: [],
      user: {},
      imgUrl: "https://s1.s.360xkw.com/",
      user: {},
      offlineList: [],
      getChatList: {
        limit: 1,
        page: 30,
      },
      isHas: true,
    };
  },
  created() {
    const userInfo = JSON.parse(localStorage.getItem("user_ID"));
    this.user = JSON.parse(localStorage.getItem("lastLogin"));
    // if (!userInfo) {
    //   this.$message.error("您还未登陆，请先登录！");
    //   setTimeout(() => {
    //     window.location.href = "/";
    //   }, 1000);
    //   return false;
    // } else {
    //   this.user = userInfo;
    // }
  },
  mounted() {
    // this.getContactList();
    this.bus.$on("receiveMessage", (msg) => {
      if (msg == "收到消息") {
        // this.getContactList();
      }
    });
    this.bus.$on("tabOnLine", (msg) => {
      if (msg == true) {
        this.isactive = -1;
      }
    });
    this.bus.$on("list2", (msg) => {
      this.list.push(...msg);
    });
    this.bus.$on("offLines", (msg) => {
      // console.log(msg);
      for (let i = 0; i < this.list.length; i++) {
        if (this.offlineList.indexOf(this.list[i].visitor_id) == -1) {
          this.offlineList.push(this.list[i].visitor_id);
        }
      }

      if (this.offlineList.indexOf(msg.visitor_id) == -1) {
        this.list.unshift(msg);
      }
    });
    this.bus.$on("onLine", (msg) => {
      // console.log("收到在线消息", msg);
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].visitor_id == msg[0].visitor_id) {
          this.list.splice(i, 1);
        }
      }
    });
    // console.log(this.list, "33333333333");
  },

  methods: {
    // 切换聊天窗口
    goChat(index, item) {
      item.online = 0;
      this.isactive = index;
      item.isOff = true;
      this.bus.$emit("youke", item);
      this.bus.$emit("tabOffLine", true);
      item.noReadNum = 0;
    },
    //
    scrollChange() {
      let that = this;
      const e = this.$refs["scrollview"];
      const scrollTop = e.scrollHeight;
    },
    async handleScroll() {
      const container = this.$refs["scrollview"];
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight && this.isHas) {
        this.getChatList.limit = Number(this.getChatList.limit) + 1;
        this.getChatList.admin_id = this.user.id;
        let a = await getVisitorColumn(this.getChatList);
        if (a.data.code == 200 && a.data.count != 0) {
          let list = a.data.data;
          // console.log(list, 999999999999999999);
          // console.log(list, 99999999);
          let onLine = [];
          let offLine = [];
          for (let i = 0; i < list.length; i++) {
            if (list[i].type == null) {
              list[i].type = 1;
            }
            // const element = array[i];
            if (list[i].online == 1) {
              onLine.push(list[i]);
            } else {
              offLine.push(list[i]);
            }
          }
          this.bus.$emit("list", onLine);
          this.bus.$emit("list2", offLine);
        } else if (a.data.code == 200 && a.data.count == 0) {
          this.isHas = false;
        }
      }
    },
  },
  filters: {
    formatDate(value) {
      if (!isNaN(value)) {
        let date = new Date(value * 1000);
        //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM; //月补0
        let d = date.getDate();
        d = d < 10 ? "0" + d : d; //天补0
        let h = date.getHours();
        h = h < 10 ? "0" + h : h; //小时补0
        let m = date.getMinutes();
        m = m < 10 ? "0" + m : m; //分钟补0
        let s = date.getSeconds();
        s = s < 10 ? "0" + s : s; //秒补0
        // return y + "-" + MM + "-" + d; //年月日
        return y + "-" + MM + "-" + d + " " + h + ":" + m; //年月日时分秒
      } else {
        // console.log(dateStr.replace(/-/gi,"/"))
        return value;
      }
    },
  },
};
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}

#contacts {
  .contacts {
    max-height: 600px;
    overflow: hidden;
    overflow-y: auto;
    > li {
      display: flex;
      height: 76px;
      align-items: center;
      box-sizing: border-box;
      padding: 0 23px 0 14px;
      .avatar {
        position: relative;
        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          vertical-align: middle;
        }
        .no_read {
          position: absolute;
          border-radius: 12px;
          background: #f5222d;
          color: #fff;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          white-space: nowrap;
          /* padding: 5px; */
          /* border-radius: 124%; */
          height: 20ox;
          height: 20px;
          width: 20px;
          left: 20px;
          top: -7px;
        }
      }
      .message {
        margin-left: 10px;
        p:nth-child(1) {
          font-size: 14px;
          font-family: Source Han Serif CN;
          font-weight: 600;
          color: #333333;
          display: flex;
          justify-content: space-between;
          span:nth-child(1) {
            display: inline-block;
            height: 24px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
            .zaixian {
              display: inline-block;
              height: 20px;
              line-height: 18px;
              width: 40px;
              font-size: 12px;
              border: 1px solid #d9ecff;
              border-radius: 4px;
              padding: 0 5px;
              background-color: #f4f4f5;
              border-color: #e9e9eb;
              color: #909399;
              box-sizing: border-box;
              margin-right: 8px;
            }
          }
          span:nth-child(2) {
            font-size: 12px;
            color: #999999;
            font-weight: 500;
            line-height: 24px;
          }
        }
        p:nth-child(2) {
          width: 220px;
          font-size: 12px;
          margin-top: 5px;
          font-family: Source Han Serif CN;
          font-weight: 500;
          color: #999999;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .addclass {
      background: #f6f6f6;
    }
  }
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>