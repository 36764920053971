<template>
  <div id="contacts">
    <ul class="contacts" ref="scrollview" @scroll="handleScroll">
      <li
        v-for="(item, index) in list"
        :key="index"
        :class="isactive == index ? 'addclass' : ''"
        @click="goChat(index, item)"
      >
        <div class="avatar">
          <img src="https://chat.xuekao123.com/uploads/img/userImg.png" alt="" />
          <span class="no_read" v-show="item.no_read_count">{{
            item.no_read_count
          }}</span>
        </div>
        <div class="message">
          <div class="zaixians"></div>
          <div>
            <p>
              <span>
                <!-- <span class="zaixian">在线</span> -->
                游客 {{ item.visitor_id }}
              </span>
              <span>{{ item.createtime | formatDate("-") }}</span>
            </p>
            <p v-if="item.content.type == '0'">
              <span></span> {{ item.content.content }}
            </p>
            <p v-if="item.content.type == '1'"><span></span> [图片]</p>
            <p v-if="item.content.type == '2'">
              <span></span>[你撤回了一条消息]
            </p>
            <p v-if="item.content.type == '3'"><span></span>点击查看消息</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { contactList } from "../api/yzb";
import { getChatList, changeRead } from "../api/xkw";
export default {
  data() {
    return {
      isactive: -1,
      list: [],
      user: {},
      imgUrl: "https://s1.s.360xkw.com/",
      user: {},
      onlineList: [],
      getChatList: {
        limit: 1,
        page: 30,
      },
      isHas: true,
    };
  },
  created() {
    const userInfo = JSON.parse(localStorage.getItem("user_ID"));
    this.user = JSON.parse(localStorage.getItem("lastLogin"));
    // if (!userInfo) {
    //   this.$message.error("您还未登陆，请先登录！");
    //   setTimeout(() => {
    //     window.location.href = "/";
    //   }, 1000);
    //   return false;
    // } else {
    //   this.user = userInfo;
    // }
  },
  mounted() {
    // this.getContactList();
    this.bus.$on("receiveMessage", (msg) => {
      if (msg == "收到消息") {
        // this.getContactList();
      }
    });
        this.bus.$on("tabOffLine", (msg) => {
      if (msg == true) {
        this.isactive = -1;
    
      }
    });
    this.bus.$on("list", (msg) => {
      this.list.push(...msg);
    });
    this.bus.$on("onLine", (msg) => {
      for (let i = 0; i < this.list.length; i++) {
        if (this.onlineList.indexOf(this.list[i].visitor_id) == -1) {
          this.onlineList.push(this.list[i].visitor_id);
        }
      }
      if (this.onlineList.indexOf(msg[0].visitor_id) == -1) {
        // console.log(msg, "+++++++++++");
        this.list.push(...msg);
      }
    });
    this.bus.$on("noRead", (msg) => {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].visitor_id == msg.id) {
          this.list[i].no_read_count = this.list[i].no_read_count + msg.read;
          this.list[i].content.content = msg.mes;
          this.list[i].content.type = msg.type;
        }
      }
    });
    this.bus.$on("offLines", (msg) => {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].visitor_id == msg.id) {
          this.list.splice(i, 1);
        }
      }
    });
    this.bus.$on("isRead", (msg) => {
      for (let i = 0; i < this.list.length; i++) {


        if (this.list[i].visitor_id == msg.id) {
          this.list[i].no_read_count = 0;
        }
      }
    });
    this.bus.$on("kfSend", (msg) => {
      // console.log(this.list,'测试');
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].visitor_id == msg.userId) {
          this.list[i].content.content = msg.mes;
          this.list[i].content.type = msg.msg_type;
          this.$forceUpdate();
        }
      }
    });
  },
  updated() {},

  methods: {
    // 切换聊天窗口
    async goChat(index, item) {
      item.isOff = false;
      this.isactive = index;
      this.bus.$emit("youke", item);
      this.bus.$emit("tabOnLine", true);
      item.no_read_count = 0;
      // console.log(item);
      item.unread = 0;
      let data = {
        admin_id: this.user.id,
        visitor_id: item.visitor_id,
      };
      let read = await changeRead(data);
      // console.log(read, "修改消息状态");
    },
    // 将消息修改成已读

    async handleScroll() {
      const container = this.$refs["scrollview"];
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight && this.isHas) {
        // console.log("滚动到底部了！"); // do something      }
        this.getChatList.limit = Number(this.getChatList.limit) + 1;
        this.getChatList.admin_id = this.user.id;
        // console.log(this.getChatList);
        let a = await getVisitorColumn(this.getChatList);
        if (a.data.code == 200 && a.data.count != 0) {
          let list = a.data.data;
          // console.log(list, 999999999999999999);
          // console.log(list, 99999999);
          let onLine = [];
          let offLine = [];
          for (let i = 0; i < list.length; i++) {
            if (list[i].type == null) {
              list[i].type = 1;
            }
            // const element = array[i];
            if (list[i].online == 1) {
              onLine.push(list[i]);
            } else {
              offLine.push(list[i]);
            }
          }
          this.bus.$emit("list", onLine);
          this.bus.$emit("list2", offLine);
        } else if (a.data.code == 200 && a.data.count == 0) {
          this.isHas = false;
        }
      }
    },
  },
  filters: {
    formatDate(value) {
      if (!isNaN(value)) {
        let date = new Date(value * 1000);
        //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM; //月补0
        let d = date.getDate();
        d = d < 10 ? "0" + d : d; //天补0
        let h = date.getHours();
        h = h < 10 ? "0" + h : h; //小时补0
        let m = date.getMinutes();
        m = m < 10 ? "0" + m : m; //分钟补0
        let s = date.getSeconds();
        s = s < 10 ? "0" + s : s; //秒补0
        // return y + "-" + MM + "-" + d; //年月日
        return y + "-" + MM + "-" + d + " " + h + ":" + m; //年月日时分秒
      } else {
        // console.log(dateStr.replace(/-/gi,"/"))
        return value;
      }
    },
  },

};
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}

#contacts {
  .contacts {
    max-height: 600px;
    overflow: hidden;
    overflow-y: auto;
    > li {
      display: flex;
      height: 76px;
      align-items: center;
      box-sizing: border-box;
      padding: 0 23px 0 14px;
      .avatar {
        position: relative;
        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          vertical-align: middle;
        }
        .no_read {
          position: absolute;
          border-radius: 12px;
          background: #f5222d;
          color: #fff;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          white-space: nowrap;
          /* padding: 5px; */
          /* border-radius: 124%; */
          height: 20px;
          width: 20px;
          line-height: 20px;
          left: 20px;
          top: -7px;
        }
      }
      .message {
        margin-left: 5px;
        display: flex;
        // align-items: center;
        .zaixians {
          display: inline-block;
          width: 6px;
          height: 6px;
          background: #04d919;
          border-radius: 50%;
          margin-top: 28px;
          margin-right: 5px;
          // line-height: 5px;
        }
        p:nth-child(1) {
          font-size: 14px;
          font-family: Source Han Serif CN;
          font-weight: 600;
          color: #333333;
          display: flex;
          justify-content: space-between;
          height: 24px;
          span:nth-child(1) {
            display: inline-block;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
            .zaixian {
              display: inline-block;
              height: 20px;
              width: 40px;
              font-size: 12px;
              border: 1px solid #d9ecff;
              border-radius: 4px;
              padding: 0 5px;
              // line-height: 20px;
              background-color: #f0f9eb;
              border-color: #e1f3d8;
              color: #67c23a;
              box-sizing: border-box;
              margin-right: 8px;
            }
          }
          span:nth-child(2) {
            font-size: 12px;
            color: #999999;
            font-weight: 500;
            line-height: 24px;
          }
        }
        p:nth-child(2) {
          width: 220px;
          font-size: 12px;
          margin-top: 2px;
          font-family: Source Han Serif CN;
          font-weight: 500;
          color: #999999;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .addclass {
      background: #f6f6f6;
    }
  }
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>