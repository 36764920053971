<template>
  <div id="contacts">
    <el-tabs class="tabs" type="border-card">
      <el-tab-pane label="当前聊天"><now-chat></now-chat> </el-tab-pane>
      <el-tab-pane label="在线"><contacts></contacts></el-tab-pane>
      <el-tab-pane label="离线" name="offLine"
        ><off-line-contacts-vue></off-line-contacts-vue
      ></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import offLineContactsVue from "../components/offLineContacts.vue";
import contacts from "../components/contacts.vue";
import NowChat from "../components/NowChat.vue";

export default {
  data() {
    return {};
  },
  components: {
    contacts,
    NowChat,
    offLineContactsVue,
  },
  methods: {
    // handleClick(tab) {
    //   if (tab.name == "offLine") {
    //     this.bus.$emit("tabOnLine", true);
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
#contacts {
  width: 300px;
  max-height: 648px;
  // background: #ffffff;
  // border-radius: 0px 0px 10px 0px;
  box-sizing: border-box;
}
::v-deep .el-tabs__item {
  width: 99px;
  padding: 0;
  text-align: center;
  padding: 0 !important;
  box-sizing: border-box;
  margin: 0 !important;
  transition: none !important;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  border: none !important;
}
::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 0;
}
::v-deep .el-tabs--border-card {
  border: none;
  box-shadow: none;
}
</style>