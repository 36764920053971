<template>
  <div id="home">
    <!-- 顶部 -->
    <div class="header">
      <!-- 顶部左侧头像 -->
      <div class="info">
        <div class="avatar" v-show="info.visitor_id">
          <img src='https://chat.xuekao123.com/uploads/img/userImg.png' alt="" />
        </div>
        <div class="names">
          <p>{{ info.userName }}</p>
          <!-- <p>{{ info.companyName }}</p> -->
        </div>
      </div>
      <!-- 右侧文字 -->
      <div>
        <img src="../../src/assets/images/wenzi1.png" alt="" />
      </div>
    </div>
    <!-- 聊天内容 -->
    <div class="body">
      <!-- <chat-view></chat-view> -->
      <service-view></service-view>
      <contacts-view></contacts-view>
    </div>
  </div>
</template>

<script>
import {
  messageList,
  sendImage,
  exchangePhone,
  rejectPhones,
  collect,
  getPosition,
} from "../api/yzb";
// import ChatView from "./ChatView.vue";
import ContactsView from "./ContactsView.vue";
import ServiceView from "./ServiceView.vue";
export default {
  data() {
    return {
      companyInfo: {},
      imgUri: "https://s1.s.360xkw.com/",
      info: {},
      user: {},
    };
  },
  beforeCreate() {
    const userInfo = JSON.parse(localStorage.getItem("user_ID"));
    const userInfo2 = JSON.parse(localStorage.getItem("chatID"));
    // if (!userInfo && !userInfo2) {
    //   this.$message.error("您还未登陆，请先登录！");
    //   setTimeout(() => {
    //     window.location.href = "/";
    //   }, 1000);
    // }
  },
  mounted() {
    this.bus.$on("youke", (msg) => {
      // console.log(msg, "-------------");
      this.info = msg;
      this.info.userName = "游客" + msg.visitor_id;
      // console.log(this.info, "----------");
    });
    // let user = JSON.parse(localStorage.getItem("user_ID"));
    // this.companyInfo.name = user.userName;
    // this.companyInfo.avatar = user.userImg;
    // this.bus.$on("chatID", (msg) => {
    //   this.info = msg;
    // });
    // this.info = JSON.parse(localStorage.getItem("chatID"));
    // const userInfo = JSON.parse(localStorage.getItem("user_ID"));
    // if (!userInfo) {
    //   this.$message.error("您还未登陆，请先登录！");
    //   setTimeout(() => {
    //     window.location.href = "/";
    //   }, 1000);
    //   return false;
    // } else {
    //   this.user = userInfo;
    // }
    // this.getPosition();
  },
  components: {
    // ChatView,
    ContactsView,
    ServiceView,
  },

  methods: {
    async getPosition() {
      let data = {
        userId: this.user.id,
        jobsId: this.info.jobsId,
      };
      let a = await getPosition(data);
      if (a.data.code == 200) {
        this.info = a.data.data;
        this.info.headUrl = a.data.data.sysUserUrl;
        this.info.userName = a.data.data.sysUserName;
        if (!a.data.data.sysUserName) {
          this.info.userName =
            a.data.data.phone.substring(0, 3) +
            "****" +
            a.data.data.phone.substring(7, 4);
        }
      }
      // console.log(a, 9999999999999999999999);
    },
  },
  watch: {},
  // beforeDestroy() {
  //   console.log(1);
  //   localStorage.removeItem("chatID");
  // },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  font-family: Source Han Serif CN;
}
#home {
  width: 800px;
  height: 708px;
  margin:20px auto;
  background: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(203, 203, 203, 0.33);
  border-radius: 10px;
  .header {
    width: 800px;
    height: 60px;
    background: #0362d2;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .info {
      display: flex;
      align-items: center;
      margin-left: 21px;
      .avatar {
        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
      }
      .names {
        margin-left: 10px;
        color: #fff;
        p:nth-child(1) {
          font-size: 16px;
          font-weight: 600;
        }
        p:nth-child(2) {
          font-size: 12px;
          font-weight: 500;
          opacity: 0.7;
        }
      }
    }
  }
  .body {
    display: flex;
  }
}
</style>
