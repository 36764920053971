import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login';
import Layout from '../views/Layout';

const push = VueRouter.prototype.push;
VueRouter.prototype.push = function (params) {
  return push.call(this, params).catch(err => err);
}

Vue.use(VueRouter)

const routes = [{
    path: '',
    redirect: '/login'
  },

  {
    path: '/login', //通过http://localhost:8080/login访问
    component: Login,
  //  beforeEnter: (to, from, next) => {
  //   // ...
  //  }
  },
  // 首页
  {
    path: '/home',
    component: Layout,
    redirect: '/home/',
    children: [{
      path: '',
      component: () => import('../views/Home')
    }]
  },
  // 数据管理
  {
    path: '/management',
    name:'management',  
    component: Layout,
    redirect: '/management/project',
    meta: {
      title: '数据管理'
    },

    children: [{
        path: 'project',
        component: () => import('../views/management/project.vue'),
        meta: {
          title: '项目站点管理'
        },

      },
      {
        path: 'region',
        component: () => import('../views/management/region.vue'),
        meta: {
          title: '地区管理'
        },

      },
      {
        path: 'squad',
        component: () => import('../views/management/squad.vue'),
        meta: {
          title: '小组管理'
        },

      }
    ]
  },
  // 访客名单
  {
    path: '/fangke',
    name:'fangke',  
    component: Layout,
    redirect: '/fangke/fkmd',
    meta: {
      title: '访客管理'
    },

    children: [{
        path: 'fkmd',
        component: () => import('../views/fangke/fkmd.vue'),
        meta: {
          title: '访客名单'
        },

      },
      {
        path: 'hmd',
        component: () => import('../views/fangke/hmd.vue'),
        meta: {
          title: '黑名单'
        },

      },
    ]
  },
  // 聊天记录
  {
    path: '/jilu',
    name:'jilu',  
    component: Layout,
    redirect: '/jilu/ltjl',
    meta: {
      title: '记录中心'
    },

    children: [{
        path: 'ltjl',
        component: () => import('../views/jilu/ltjl.vue'),
        meta: {
          title: '聊天记录'
        },

      },
      {
        path: 'leave',
        component: () => import('../views/jilu/leave.vue'),
        meta: {
          title: '留言管理'
        },

      },
    ]
  },
  // 风格设置
  {
    path: '/fengge',
    name:'fengge',  
    component: Layout,
    redirect: '/fengge/fkPC',
    meta: {
      title: '风格设置'
    },

    children: [{
        path: 'fkPC',
        component: () => import('../views/fengge/fkPC.vue'),
        meta: {
          title: '访客端-PC端'
        },

      },
      {
        path: 'fkYD',
        component: () => import('../views/fengge/fkYD.vue'),
        meta: {
          title: '访客端-移动端'
        },

      },
    ]
  },

]

const router = new VueRouter({
  mode: 'hash',
  routes
})

// 全局路由前置守卫
router.beforeEach((to, from, next) => {
  // to:去到哪里
  // from:从哪里来
  // next:做什么事,跳转到下一个页面
  // 登录页面返回token,未登录状态下没有token
  let token = localStorage.getItem('token');
  if (token) {
    next();
  } else {
    // 当前在登录页面
    if (to.path === '/login') {
      next();
    } else {
      // 当前不在登录页面,且没有token
      next('/login');
    }
  }
})

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    window.location.reload();
    // router.replace(router.history.pending.fullPath);
  }else{
    console.log(error)
  }
});


export default router